import React from "react";

const GoogleIcon = () => {
  return (
    <a href="https://play.google.com/store/apps/details?id=gr.app.loox&rdid=gr.app.loox">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        version="1.1"
        viewBox="185.5 18.2 128 40.5"
        xmlSpace="preserve"
      >
        <path d="M309.5 58.5h-120c-2.2 0-4-1.8-4-4v-32c0-2.2 1.8-4 4-4h120c2.2 0 4 1.8 4 4v32c0 2.2-1.8 4-4 4z" />
        <linearGradient
          id="a"
          x1="201.767"
          x2="207.366"
          y1="25.074"
          y2="48.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#006884" />
          <stop offset="1" stopColor="#8AD1D0" />
        </linearGradient>
        <path
          fill="url(#a)"
          fillRule="evenodd"
          d="M195.3 38.1V27.6c0-.6.5-1.1 1-1.1s.7.1 1 .3l19.3 10.6c.4.2.6.5.6.8 0 .3-.2.6-.6.8l-19.3 10.6c-.2.1-.5.3-1 .3s-1-.5-1-1.1V38.1z"
          clipRule="evenodd"
        />
        <linearGradient
          id="b"
          x1="194.941"
          x2="210.197"
          y1="28.344"
          y2="36.591"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#24BBB6" />
          <stop offset="1" stopColor="#DBE692" />
        </linearGradient>
        <path
          fill="url(#b)"
          fillRule="evenodd"
          d="M207.8 38.2l-11.7-11.7h.2c.5 0 .7.1 1 .3l14.2 7.8-3.7 3.6z"
          clipRule="evenodd"
        />
        <linearGradient
          id="c"
          x1="212.494"
          x2="212.494"
          y1="42.12"
          y2="34.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#FCC072" />
          <stop offset="1" stopColor="#F58A5B" />
        </linearGradient>
        <path
          fill="url(#c)"
          fillRule="evenodd"
          d="M211.4 41.7l-3.6-3.6 3.7-3.7 5.1 2.8c.4.2.6.5.6.8 0 .3-.2.6-.6.8l-5.2 2.9z"
          clipRule="evenodd"
        />
        <linearGradient
          id="d"
          x1="197.895"
          x2="209.946"
          y1="51.769"
          y2="39.504"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#712B8F" />
          <stop offset="1" stopColor="#EA1D27" />
        </linearGradient>
        <path
          fill="url(#d)"
          fillRule="evenodd"
          d="M196.2 49.7l11.6-11.6 3.6 3.6-14.1 7.7c-.3.2-.5.3-1.1.3.1 0 .1 0 0 0z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M256.6 52.4c-.5-.4-.9-1.1-1.1-1.5l1.6-.7c.1.2.3.5.5.8.4.4 1 .8 1.6.8.6 0 1.3-.3 1.7-.8.3-.5.5-1 .5-1.7v-.6c-1.2 1.5-3.7 1.3-5.1-.3-1.5-1.6-1.5-4.3 0-5.9 1.5-1.5 3.7-1.7 5-.3v-.7h1.7V49c0 1.9-.7 3-1.6 3.7-.6.5-1.5.7-2.3.7-.9-.1-1.8-.4-2.5-1zm41.9.4l1.7-4-3-6.9h1.7l2.1 4.9 2.1-4.9h1.7l-4.6 10.9h-1.7zm-8.1-3.8c-.5-.5-.7-1.2-.7-1.9 0-.6.2-1.2.6-1.6.7-.7 1.7-1 2.8-1 .7 0 1.3.1 1.8.4 0-1.2-1-1.7-1.8-1.7-.7 0-1.4.4-1.7 1.1l-1.5-.6c.3-.7 1.1-2 3.1-2 1 0 2 .3 2.6 1 .6.7.8 1.5.8 2.6v4.2h-1.7v-.7c-.2.3-.6.5-.9.7-.4.2-.9.3-1.4.3-.6-.1-1.5-.3-2-.8zm-53.3-3.6c0-2 1.5-4.2 4.2-4.2 2.6 0 4.2 2.2 4.2 4.2s-1.5 4.2-4.2 4.2-4.2-2.2-4.2-4.2zm9 0c0-2 1.5-4.2 4.2-4.2 2.6 0 4.2 2.2 4.2 4.2s-1.5 4.2-4.2 4.2c-2.6 0-4.2-2.2-4.2-4.2zm-20.3 2.2c-2.5-2.5-2.4-6.6.1-9.2 1.3-1.3 2.9-1.9 4.6-1.9 1.6 0 3.2.6 4.4 1.8l-1.2 1.3c-1.8-1.8-4.7-1.7-6.4.1-1.8 1.9-1.8 4.8 0 6.7 1.8 1.9 4.8 2 6.6.1.6-.6.8-1.4.9-2.2h-4.2v-1.8h5.9c.1.4.1.9.1 1.4 0 1.5-.6 3-1.6 4-1.1 1.1-2.7 1.7-4.3 1.7-1.8-.1-3.6-.7-4.9-2zm42.3.7c-1.5-1.6-1.5-4.3 0-6 1.5-1.6 4-1.6 5.4 0 .5.5.8 1.2 1.1 1.9l-5.5 2.3c.3.7 1 1.3 2 1.3.9 0 1.5-.3 2.1-1.2l1.5 1c-.2.2-.4.4-.5.6-1.7 1.7-4.6 1.7-6.1.1zm10.4 1.2V38.4h3.6c2.1 0 3.8 1.5 3.8 3.3 0 1.8-1.5 3.3-3.4 3.3h-2.2v4.4h-1.8v.1zm8.4 0V38.4h1.7v11.1h-1.7zm-22.6-.2V36.9h1.8v12.4h-1.8zm30.5-3.1c-.4-.3-1-.4-1.6-.4-1.2 0-1.9.6-1.9 1.3 0 .7.7 1.1 1.4 1.1 1 0 2.1-.8 2.1-2zm-51.2-.8c0-1.2-.8-2.5-2.3-2.5-1.5 0-2.3 1.3-2.3 2.5s.8 2.5 2.3 2.5c1.4 0 2.3-1.3 2.3-2.5zm9 0c0-1.2-.8-2.5-2.3-2.5-1.5 0-2.3 1.3-2.3 2.5s.8 2.5 2.3 2.5c1.5 0 2.3-1.3 2.3-2.5zm8.9.7c0-.1 0-.2.1-.3v-.4-.4-.1c0-.1-.1-.3-.1-.4-.3-1-1.2-1.7-2.1-1.7-1.2 0-2.2 1.2-2.2 2.5 0 1.4 1 2.5 2.3 2.5.8.1 1.6-.6 2-1.7zm7.3-.9l3.7-1.6c-.4-.7-1-.9-1.5-.9-1.5.1-2.4 1.7-2.2 2.5zm15.3-3.4c0-1-.8-1.7-1.9-1.7h-2v3.5h2.1c1 0 1.8-.8 1.8-1.8z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.8 50.8L299.8 50.8 299.7 50.8 299.7 50.8 299.7 50.7 299.7 50.7 299.8 50.7 299.8 50.7 299.7 50.7 299.7 50.7 299.7 50.8 299.7 50.8z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.8 50.8c0-.1 0-.1 0 0 0-.1 0-.1 0 0zm0-.1z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.8 50.8L299.8 50.8 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.8 299.8 50.8z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.8 50.8zm0-.1z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.8 50.7L299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.8 299.8 50.8 299.8 50.7 299.8 50.7z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.8 50.8L299.8 50.8 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.7 299.8 50.8 299.8 50.8z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.8 50.8c0-.1 0-.1 0 0zm0 0c0-.1 0-.1 0 0zM299.9 50.8c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zm0-.1zm0 .1c0-.1 0-.1 0 0z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.9 50.7L299.9 50.7 299.9 50.7 299.9 50.7 299.9 50.7 299.9 50.7 299.9 50.7 299.9 50.7 299.9 50.8 299.9 50.8 299.9 50.7z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.9 50.8L299.9 50.8 299.9 50.7 299.9 50.7 299.9 50.8 299.9 50.8z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M299.9 50.8c0-.1 0-.1 0 0 0-.1 0-.1 0 0zm0-.1c0 .1 0 .1 0 0 0 .1 0 .1 0 0zM299.9 50.8c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM300 50.8c0-.1 0-.1 0 0-.1-.1-.1-.1 0 0-.1 0-.1 0 0 0zm0-.1c0 .1 0 .1 0 0 0 .1-.1.1 0 0-.1 0 0 0 0 0z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M300 50.8L300 50.7 300 50.7 300 50.7 300 50.7 300 50.7 300 50.8 300 50.8 300 50.7 300 50.7 300 50.8 300 50.8 300 50.7 300 50.7 300 50.8z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M300 50.8zm0-.1zM300 50.8c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M300.1 50.7L300 50.7 300 50.7 300.1 50.7 300.1 50.8 300.1 50.8 300.1 50.7 300.1 50.7z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M300.1 50.8L300.1 50.8 300.1 50.7 300.1 50.7 300.1 50.7 300.1 50.7 300.1 50.7 300.1 50.7 300.1 50.7 300.1 50.7 300.1 50.8 300.1 50.8z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M300.1 50.8c0-.1 0-.1 0 0 0-.1 0-.1 0 0zm0-.1z"
          clipRule="evenodd"
        />
        <path
          fill="#FFF"
          d="M224.4 28.6c0-1.8 1.3-2.9 2.9-2.9 1.1 0 1.8.5 2.3 1.2l-.8.5c-.3-.4-.8-.7-1.5-.7-1.1 0-1.9.8-1.9 2s.8 2 1.9 2c.6 0 1.1-.3 1.3-.5v-.9H227v-.9h2.7v2.1c-.5.6-1.3 1-2.3 1-1.7 0-3-1.2-3-2.9zM230.9 31.4v-5.6h3.8v.9h-2.9v1.4h2.8v.9h-2.8v1.6h2.9v.9l-3.8-.1zM237.5 31.4v-4.7h-1.7v-.9h4.4v.9h-1.7v4.7h-1zM243.6 31.4v-5.6h1v5.6h-1zM247.3 31.4v-4.7h-1.7v-.9h4.4v.9h-1.7v4.7h-1zM253.2 28.6c0-1.7 1.2-2.9 2.9-2.9s2.9 1.2 2.9 2.9-1.2 2.9-2.9 2.9-2.9-1.3-2.9-2.9zm4.7 0c0-1.2-.7-2-1.9-2-1.1 0-1.9.9-1.9 2 0 1.2.7 2 1.9 2s1.9-.9 1.9-2zM264 31.4l-2.9-4v4h-1v-5.6h1l2.9 3.9v-3.9h1v5.6h-1z"
        />
      </svg>
    </a>
  );
}

export default GoogleIcon;
