import React from 'react'

const LogoFull = () => {

  return (

    <svg version="1.1" viewBox="-200 375.9 195 60" x="0px" y="0px" xmlns="http://www.w3.org/2000/svg">

      <g transform="translate(530 226) scale(0.94)">
        <path d="M-739.8,217.1c-6.2,1.5-17.2-8.2-24.7-21.5c-7.5-13.3-8.5-25.3-2.4-26.8s17.2,8.2,24.7,21.5&#xA;&#x9;&#x9;C-734.7,203.6-733.6,215.6-739.8,217.1z" fill='white' opacity='0.3'/>
        <path d="M-752.8,191.5a11.9,25.7 0 1,0 23.8,0a11.9,25.7 0 1,0 -23.8,0z" opacity='0.6' fill='white'/>
        <path d="M-715.2,168.7c6.1,1.5,5.1,13.5-2.4,26.8c-7.5,13.3-18.5,23-24.6,21.5&#xA;&#x9;&#x9;c-6.2-1.5-5.1-13.5,2.4-26.8C-732.4,176.9-721.4,167.3-715.2,168.7z" fill='white' opacity='0.9' />
      </g>


      <g transform="translate(0, -20)">

            <g transform="translate(0, 2)">
        <path d="M-117.6,448.9v-50.3h3.1v50.3H-117.6z" opacity='0.9' fill='white' />
      </g>
        <path fill='white' opacity='0.9' d="M-90,449.7c-5,0-8.9-1.6-11.8-4.9c-2.9-3.3-4.3-7.5-4.3-12.7c0-5.6,1.5-10.1,4.5-13.2c3-3.2,7-4.8,11.9-4.8&#xA;&#x9;&#x9;c4.8,0,8.7,1.6,11.5,4.7c2.8,3.2,4.2,7.5,4.2,13.1c0,5.3-1.4,9.6-4.3,12.8C-81.1,448.1-85,449.7-90,449.7z M-89.7,416.8&#xA;&#x9;&#x9;c-4,0-7.2,1.4-9.6,4.1c-2.4,2.7-3.6,6.5-3.6,11.3c0,4.4,1.2,8,3.5,10.7c2.3,2.7,5.5,4.1,9.5,4.1c4.1,0,7.2-1.3,9.5-4&#xA;&#x9;&#x9;c2.2-2.7,3.3-6.3,3.3-11.1c0-4.8-1.1-8.6-3.3-11.2C-82.6,418.1-85.7,416.8-89.7,416.8z"  />

            <path fill='white' opacity='0.9' d="M-51.8,449.7c-5,0-8.9-1.6-11.8-4.9c-2.9-3.3-4.3-7.5-4.3-12.7c0-5.6,1.5-10.1,4.5-13.2c3-3.2,7-4.8,11.9-4.8&#xA;&#x9;&#x9;c4.8,0,8.7,1.6,11.5,4.7c2.8,3.2,4.2,7.5,4.2,13.1c0,5.3-1.4,9.6-4.3,12.8C-42.9,448.1-46.8,449.7-51.8,449.7z M-51.6,416.8&#xA;&#x9;&#x9;c-4,0-7.2,1.4-9.6,4.1c-2.4,2.7-3.6,6.5-3.6,11.3c0,4.4,1.2,8,3.5,10.7c2.3,2.7,5.5,4.1,9.5,4.1c4.1,0,7.2-1.3,9.5-4&#xA;&#x9;&#x9;c2.2-2.7,3.3-6.3,3.3-11.1c0-4.8-1.1-8.6-3.3-11.2C-44.4,418.1-47.6,416.8-51.6,416.8z" />

        <path fill='white' opacity='0.9' d="M-18.1,432.2L-7,448.9h-3.9l-9-14.3H-20l-1.2,1.9c-0.2,0.3-0.3,0.6-0.6,0.9l-7.5,11.6H-33l11.4-16.6&#xA;&#x9;&#x9;l-11.2-17.4h3.6l7.6,12.2c1,1.6,1.6,2.5,1.8,2.9h0.1l1.7-2.8l7.9-12.3h3.6L-18.1,432.2z"/>
      </g>

    </svg>

  )

}

export default LogoFull
