import React from 'react';
import './App.css';
import Home from './routes/Home'
import Verify from './routes/Verify'
import NoMatch from './routes/NoMatch'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import ApolloClient from 'apollo-client'
import { ApolloProvider } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

// const uriHttp = 'http://46.101.146.22:8081/graphql'
const uriHttp = 'https://looxapp.gr:8081/graphql'

// const uriHttp = 'http://192.168.1.2:8081/graphql'

const httpLink = new HttpLink({
  uri: uriHttp,
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});


function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/verify/:linkId' component={Verify} />
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
