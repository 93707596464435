import React from "react";

function LooxLogo({ style }) {
  return (


    <svg style={style} viewBox="0 0 1000 840">
        	<g id="correct_logo" transform="translate(100 280)">
        		<path opacity='0.3' fill='#a649f2' d="M406.2,454.5c-75.8,18-211.9-100.9-303.8-265.4S-2.7-123.4,73.1-141.4S285-40.5,377,124 S482,436.5,406.2,454.5z"/>
        		<path opacity='0.6' fill='#a649f2' d="M392.9-178.2c80.7,0,146.1,141.9,146.1,316.9s-65.4,316.9-146.1,316.9S246.8,313.7,246.8,138.7 S312.2-178.2,392.9-178.2z"/>
        		<path opacity='0.9' fill='#a649f2' d="M708.9-141.4c75.8,18,62.7,165.9-29.3,330.3s-228,283.3-303.8,265.3s-62.7-165.9,29.3-330.3 S633.1-159.4,708.9-141.4z"/>
        	</g>
        </svg>


)
}
export default LooxLogo
