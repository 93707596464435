import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';
// WebFont.load({
//   google: {
//     families: ['Averta:greek', ]
//   }
// });
WebFont.load({
  google: {
    families: ['Open Sans' ]
  }
});
// WebFont.load({
//   custom: {
//     families: ['Averta-Regular', 'Averta-Semibold', 'Averta-Light'],
//     // urls: ['assets/css/fonts.css']
//   }
// });
ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
