import React from "react";

const PageNotFoundIcon = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlnsXlink="http://www.w3.org/1999/xlink"
      // width="640"
      // height="640"
      width='280'
      height='280'
      viewBox="0 0 480 480"
    >
      <linearGradient id="a">
        <stop offset="0" stopColor="#9d37f1" />
        <stop offset="0.36" stopColor="#a649f2" />
        <stop offset="0.716" stopColor="#b86ef5" />
        <stop offset="1" stopColor="#ca92f7" />
        {/* <stop offset="0" stopColor="#e93e3a" />
        <stop offset="0.36" stopColor="#ed683c" />
        <stop offset="0.716" stopColor="#f3903f" />
        <stop offset="1" stopColor="#fdc70c" /> */}
      </linearGradient>
      <linearGradient
        id="b"
        x1="237.904"
        x2="242.28"
        y1="480.736"
        y2="-21.024"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="c"
        x1="52.168"
        x2="56.544"
        y1="479.112"
        y2="-22.648"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="d"
        x1="84.168"
        x2="88.544"
        y1="479.392"
        y2="-22.368"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="e"
        x1="116.168"
        x2="120.544"
        y1="479.672"
        y2="-22.088"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="f"
        x1="284.152"
        x2="288.528"
        y1="481.136"
        y2="-20.624"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="g"
        x1="130.432"
        x2="134.808"
        y1="479.792"
        y2="-21.968"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="h"
        x1="346.416"
        x2="350.792"
        y1="481.68"
        y2="-20.08"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="i"
        x1="238.144"
        x2="242.52"
        y1="480.736"
        y2="-21.024"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="j"
        x1="101.28"
        x2="105.656"
        y1="479.544"
        y2="-22.216"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="k"
        x1="237.272"
        x2="241.648"
        y1="480.728"
        y2="-21.032"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="l"
        x1="373.264"
        x2="377.64"
        y1="481.912"
        y2="-19.848"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="m"
        x1="103.024"
        x2="107.4"
        y1="479.552"
        y2="-22.2"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="n"
        x1="239.016"
        x2="243.392"
        y1="480.744"
        y2="-21.016"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <linearGradient
        id="o"
        x1="375.008"
        x2="379.384"
        y1="481.928"
        y2="-19.832"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#a"
      />
      <path
        fill="url(#b)"
        d="M440 0H40C17.918.027.027 17.918 0 40v400c.027 22.082 17.918 39.973 40 40h400c22.082-.027 39.973-17.918 40-40V40c-.027-22.082-17.918-39.973-40-40zM40 16h400c13.254 0 24 10.746 24 24v24H16V40c0-13.254 10.746-24 24-24zm400 448H40c-13.254 0-24-10.746-24-24V80h448v360c0 13.254-10.746 24-24 24zm0 0"
      />
      <path fill="url(#c)" d="M48 32h16v16H48zm0 0" />
      <path fill="url(#d)" d="M80 32h16v16H80zm0 0" />
      <path fill="url(#e)" d="M112 32h16v16h-16zm0 0" />
      <path fill="url(#f)" d="M176 32h224v16H176zm0 0" />
      <path
        fill="url(#g)"
        d="M144 304v24h16v-24h24v-16h-24v-72a8 8 0 00-14.246-5l-64 80a7.995 7.995 0 00-.969 8.465A8.005 8.005 0 0088 304zm0-65.191V288h-39.352zm0 0"
      />
      <path
        fill="url(#h)"
        d="M376 216a8 8 0 00-14.246-5l-64 80a7.995 7.995 0 00-.969 8.465A8.005 8.005 0 00304 304h56v24h16v-24h24v-16h-24zm-16 22.809V288h-39.352zm0 0"
      />
      <path
        fill="url(#i)"
        d="M224 208c-13.254 0-24 10.746-24 24v72c0 13.254 10.746 24 24 24h32c13.254 0 24-10.746 24-24v-72c0-13.254-10.746-24-24-24zm40 24v72a8 8 0 01-8 8h-32a8 8 0 01-8-8v-72a8 8 0 018-8h32a8 8 0 018 8zm0 0"
      />
      <path fill="url(#j)" d="M88 160h32v16H88zm0 0" />
      <path fill="url(#k)" d="M136 160h208v16H136zm0 0" />
      <path fill="url(#l)" d="M360 160h32v16h-32zm0 0" />
      <path fill="url(#m)" d="M88 360h32v16H88zm0 0" />
      <path fill="url(#n)" d="M136 360h208v16H136zm0 0" />
      <path fill="url(#o)" d="M360 360h32v16h-32zm0 0" />
    </svg>
  );
}

export default PageNotFoundIcon;
