import React from "react";

const InstagramIcon = () => {
  return (
    <a href="https://www.instagram.com/looxdating">
    <svg viewBox="0 0 512 512" transform="scale(1)" xmlns="http://www.w3.org/2000/svg">
      <g fill='rgb(230,230,230)' opacity='0.6'>
        <path d="m399.945312 512h-287.890624c-61.785157 0-112.054688-50.269531-112.054688-112.054688v-287.890624c0-61.785157 50.269531-112.054688 112.054688-112.054688h287.890624c61.785157 0 112.054688 50.269531 112.054688 112.054688v287.890624c0 61.785157-50.269531 112.054688-112.054688 112.054688zm-287.890624-482c-45.246094 0-82.054688 36.808594-82.054688 82.054688v287.890624c0 45.246094 36.808594 82.054688 82.054688 82.054688h287.890624c45.246094 0 82.054688-36.808594 82.054688-82.054688v-287.890624c0-45.246094-36.808594-82.054688-82.054688-82.054688zm0 0"/>
        <path d="m256 410.382812c-85.125 0-154.382812-69.257812-154.382812-154.382812s69.257812-154.382812 154.382812-154.382812 154.382812 69.257812 154.382812 154.382812-69.257812 154.382812-154.382812 154.382812zm0-278.765624c-68.585938 0-124.382812 55.796874-124.382812 124.382812s55.796874 124.382812 124.382812 124.382812 124.382812-55.796874 124.382812-124.382812-55.796874-124.382812-124.382812-124.382812zm0 0"/>
        <path d="m413.9375 117.824219c-3.371094 0-6.785156-.855469-9.910156-2.660157-4.601563-2.65625-7.890625-6.945312-9.265625-12.074218-1.375-5.132813-.671875-10.496094 1.984375-15.097656 2.65625-4.597657 6.945312-7.886719 12.078125-9.261719 5.128906-1.378907 10.488281-.671875 15.089843 1.984375 4.601563 2.65625 7.894532 6.945312 9.269532 12.078125 1.375 5.128906.667968 10.488281-1.988282 15.089843-3.679687 6.375-10.378906 9.941407-17.257812 9.941407zm0 0"/>
      </g>
    </svg>
  </a>
  );
}

export default InstagramIcon;
