import React from 'react';

import { Fade } from 'react-slideshow-image';

import '../css/Home.css';

import LogoFull from '../components/LogoFull'
import GoogleIcon from '../components/GoogleIcon'
import FacebookIcon from '../components/FacebookIcon'
import AppleIcon from '../components/AppleIcon'
import InstagramIcon from '../components/InstagramIcon'


const fadeProperties = {
  duration: 3200,
  transitionDuration: 450,
  infinite: true,
  indicators: false,
  arrows: false,
}

class Home extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      windowWidth: window.innerWidth,
    }

  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  componentDidMount = () => {

    window.addEventListener('resize', this.handleResize);

    const s = document.createElement("script");

    s.type = 'text/javascript';
    s.async = true;

    s.innerHTML = '"use strict"; !function() { var t = window.driftt = window.drift = window.driftt || [];if (!t.init) { if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice.")); t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], t.factory = function(e) { return function() { var n = Array.prototype.slice.call(arguments); return n.unshift(e), t.push(n), t; }; }, t.methods.forEach(function(e) { t[e] = t.factory(e); }), t.load = function(t) { var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script"); o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js"; var i = document.getElementsByTagName("script")[0]; i.parentNode.insertBefore(o, i); }; } }(); drift.SNIPPET_VERSION = "0.3.1";drift.load("iigxc7276xws");'

    document.body.appendChild(s);

  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  }


  render() {

    return (

      <div className='backgroudImage'>

        {/* <div ref={el => (this.instance = el)} /> */}


        <div className='logoContainer'>
          <LogoFull />
        </div>

        <div className='mainContainer'>

          <div className='leftContainer'>

            <h1 className='mainText semibold'>Η νέα εμπειρία dating ήρθε στην Θεσσαλονίκη!</h1>

            <div className='descriptionText light'>Το <span className='semibold'>Loox</span> σου επιτρέπει να βγεις συναρπαστικά ραντεβού στο μαγαζί της αρεσκείας σου, εύκολα και απλά</div>

            {this.state.windowWidth >= 760 &&
              <div className='storeIcons'>
                <div className='storeIcon storeIconRightMargin'>
                  <AppleIcon />
                </div>
                <div className='storeIcon'>
                  <GoogleIcon />
                </div>
              </div>
            }

          </div>

          <div className='rightContainer'>

            <Fade className='fadeContainer' {...fadeProperties}>
              <img className='rightImage' src={require('../assets/images/1_transformed.png')} />
              <img className='rightImage' src={require('../assets/images/2_transformed.png')} />
              <img className='rightImage' src={require('../assets/images/3_transformed.png')} />
            </Fade>

            {this.state.windowWidth < 760 &&
              <div className='storeIcons'>
                <div className='storeIcon storeIconRightMargin'>
                  <AppleIcon />
                </div>
                <div className='storeIcon'>
                  <GoogleIcon />
                </div>
              </div>
            }

          </div>

        </div>

        <div className='socialIcons'>
          <div className='socialIcon socialIconRightMargin'>
            <InstagramIcon />
          </div>
          <div className='socialIcon'>
            <FacebookIcon />
          </div>
        </div>

      </div>
    )

  }
}


export default Home
