import React from 'react';
import PageNotFoundIcon from '../components/PageNotFoundIcon'
import '../css/NoMatch.css';

import { Link } from "react-router-dom";

const NoMatch = () => {

  return (
    <div className='matchContainer'>

      <PageNotFoundIcon />

      <div className='matchText'>
        Δεν μπορούμε να βρούμε την σελίδα που ψάχνεις, ίσως έχει βγει ραντεβού.
      </div>

      <Link style={{ textDecoration: 'none' }} to={{ pathname: '/' }}>
        <div className='goOutText'>
          Βγες κι εσύ
        </div>
      </Link>

    </div>
  )

}

export default NoMatch
