import React from 'react';

import gql from 'graphql-tag'
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';

import LooxLogo from '../components/LooxLogo'

import { Link } from "react-router-dom";


class Verify extends React.Component {

  render() {

    if (this.props.checkEmailVerificationLinkQuery.error) {

      return (
        <div className='matchContainer'>

          <LooxLogo style={{ width: 'calc(10vw + 100px)' }} />

          <div className='matchText'>
            Ουπς, κάποιο σφάλμα προέκυψε :(
          </div>

          <Link style={{ textDecoration: 'none' }} to={{ pathname: '/' }}>
            <div className='goOutText'>
              Κατέβασε το app
            </div>
          </Link>

        </div>
      )

    }

    return (
      <div className='matchContainer'>

        {this.props.checkEmailVerificationLinkQuery && this.props.checkEmailVerificationLinkQuery.hasOwnProperty('checkEmailVerificationLink') &&
          <>
            <LooxLogo style={{ width: 'calc(10vw + 100px)' }} />

            <div className='matchText'>
              {this.props.checkEmailVerificationLinkQuery.checkEmailVerificationLink.code === 100 ? 'Το email σου επιβεβαιώθηκε!' : 'Το link που έκανες κλικ έχει λήξει (ή δεν υπήρξε ποτέ)'}
            </div>

            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/' }}>
              <div className='goOutText'>
                Κατέβασε το app
              </div>
            </Link>
          </>
        }

      </div>
    )

  }

}


const checkEmailVerificationLinkQuery = gql`
  query checkEmailVerificationLinkQuery($linkId: String) {
    checkEmailVerificationLink(linkId: $linkId) {
      code
    }
  }
`;

export default withApollo( compose(
  graphql(checkEmailVerificationLinkQuery, {
    name: "checkEmailVerificationLinkQuery",
    fetchPolicy: 'network-only',
    options: (ownProps) => ({
      fetchPolicy: 'network-only',
      variables: { linkId: ownProps.match.params.linkId },
    }),
  }),
)(Verify));
